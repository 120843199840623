import Card from '@mui/material/Card';
import { useEffect, useState } from 'react';

import Layout from '../../components/layout';
import UserDepartment from '../../models/userDepartment/UserDepartment';
import UserDepartmentService from '../../services/UserDepartmentService';
import UserService from '../../services/UserService';

export default function UserProfile() {
  const [userData, setUserData] = useState([]);
  const [imageSrc, setImageSrc] = useState('');
  const [userDepartments, setUserDepartments] = useState([]);
  const [imageLoadError, setImageLoadError] = useState(false);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const parsedUser = JSON.parse(storedUser);

    const fetchUserData = async () => {
      const user_details = await UserService.findOne(parsedUser.id);
      setUserData(user_details['user'][0]);

      const user_department = await UserDepartmentService.find(parsedUser.id);
      console.log(user_department);
      if (!user_department['error']) {
        setUserDepartments(user_department['user_department']);
      }
    };

    const fetchImage = async () => {
      try {
        const response = await fetch(
          `https://hrportals.ainsofttech.com/api/api/users/profile_image/${parsedUser.id}`,
        );
        if (response.ok) {
          const blob = await response.blob();
          const imageUrl = URL.createObjectURL(blob);
          setImageSrc(imageUrl);
          setImageLoadError(false);
        } else {
          setImageLoadError(true);
        }
      } catch (error) {
        setImageLoadError(true);
      }
    };

    fetchUserData();
    fetchImage();
  }, []);

  return (
    <Layout>
      <div className="profile__screen">
        <h4 className="responsive_screens_heading">Profile Screen</h4>
        <div className="dashboard__first">
          <Card className="profile__card" variant="outlined">
            <div
              style={{
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderBottomColor: 'rgba(112, 112, 112, 0.15)',
              }}
            >
              <div className="profile__screen__body">
                <div className="profile__image">
                  {imageLoadError ? (
                    <img
                      style={{
                        maxWidth: '100%',
                        height: '80%',
                        borderRadius: '15px',
                        margin: 'auto',
                      }}
                      src="/avatar_male.png"
                      alt="Avatar"
                    />
                  ) : (
                    <img
                      style={{
                        maxWidth: '100%',
                        height: '80%',
                        borderRadius: '15px',
                        margin: 'auto',
                      }}
                      src={imageSrc}
                      alt="User Profile"
                    />
                  )}
                </div>

                <div className="profile__body">
                  <div className="profile__body_header">
                    <h3 className="profile__headings">
                      {userData['first_name']} {userData['last_name']}
                    </h3>
                    <p
                      className="profile__paragraph"
                      style={{ marginTop: '1vw' }}
                    >
                      {userData['designation_name']}
                    </p>
                  </div>
                  <div className="profile__body_body">
                    <div
                      className="profile__body_details"
                      style={{ paddingRight: '10vw' }}
                    >
                      <div className="profile__body_single_detail">
                        <h3 className="profile__headings">Phone no.</h3>
                        <p className="profile__paragraph">
                          {userData['phone_no']}
                        </p>
                      </div>
                      <div className="profile__body_single_detail">
                        <h3 className="profile__headings">E-mail</h3>
                        <p className="profile__paragraph">
                          {userData['email']}
                        </p>
                      </div>
                      <div className="profile__body_single_detail">
                        <h3 className="profile__headings">Address</h3>
                        <p className="profile__paragraph">
                          {userData['address']}
                        </p>
                      </div>
                    </div>
                    <div className="profile__body_details">
                      <div className="profile__body_single_detail">
                        <h3 className="profile__headings">Department</h3>
                        <p className="profile__paragraph">
                          {userData['department_name']}
                        </p>
                      </div>
                      <div className="profile__body_single_detail">
                        <h3 className="profile__headings">Joining Date</h3>
                        <p className="profile__paragraph">
                          {userData['joiningDate']
                            ? userData['joiningDate']
                            : '8 November, 2021'}
                        </p>
                      </div>
                      <div className="profile__body_single_detail">
                        <h3 className="profile__headings">Office City</h3>
                        <p className="profile__paragraph">
                          {userData['office_city']}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <h4 className="attendance_overview_heading">Employee History</h4>
        <div className="dashboard__first">
          <Card className="profile_employement_card" variant="outlined">
            <div>
              <div className="timeline">
                {userDepartments.map((row, rowIndex) => (
                  <div key={rowIndex} className="entry">
                    {rowIndex === 0 ? (
                      <div className="timeline-icon active"></div>
                    ) : (
                      <div className="timeline-icon"></div>
                    )}

                    <div className="employee_history_title">
                      {row.designation_name}
                      {row.status !== 'permanent' && ` (${row.status})`}
                    </div>
                    <div className="employee_history_description">
                      {row.started_at} - {row.ended_at}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Layout>
  );
}
