import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import moment from 'moment';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import Layout from '../../components/layout';
import AttendanceService from '../../services/AttendanceService';
import SpecialRequestService from '../../services/specialRequestService';
import UserService from '../../services/UserService';
import ValidationModal from '../BootstrapValidationModal';
import RejectionModal from '../RejectionModal';

export default function ViewSpecialRequest() {
  const { id } = useParams() as { id: string };
  const history = useHistory();

  const [specialRequestDetails, setSpecialRequestDetails] = useState([]);
  const [isTeamLead, setIsTeamLead] = useState(false);
  const [approveButtonDisabled, setApproveButtonDisabled] = useState(false);
  const [rejectButtonDisabled, setRejectButtonDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [notedButtonDisabled, setNotedButtonDisabled] = useState(false);

  const [currentStatus, setCurrentStatus] = useState('');
  const [descriptionText, setDescriptionText] = useState('');
  const [isSubmittingModalOpen, setIsSubmittingModalOpen] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    let intervalId;
    let isMounted = true;
    setIsModalOpen(false);
    setCurrentStatus('');
    const startProgress = () => {
      intervalId = setInterval(() => {
        setProgress((oldProgress) => {
          const newProgress = oldProgress + 100 / steps;
          if (newProgress >= 100) {
            clearInterval(intervalId);
            setTimeout(() => {
              if (isMounted) {
                setIsSubmittingModalOpen(false);
                setIsSubmitting(false);
                history.push(`/special`);
              }
            }, 500);
            return 100;
          }
          return newProgress;
        });
      }, intervalDuration);
    };

    const intervalDuration = 30;
    const totalTime = 10000;
    const steps = totalTime / intervalDuration;
    if (isSubmittingModalOpen) {
      startProgress();
    }

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [isSubmittingModalOpen]);

  useEffect(() => {
    const fetchUserData = async (id) => {
      const specialRequestData = await SpecialRequestService.findOne(id);

      if (specialRequestData[0].status === 'approved') {
        setApproveButtonDisabled(true);
      } else {
        setApproveButtonDisabled(false);
      }
      if (specialRequestData[0].status === 'rejected') {
        setRejectButtonDisabled(true);
      } else {
        setRejectButtonDisabled(false);
      }
      if (specialRequestData[0].status === 'noted') {
        setNotedButtonDisabled(true);
      } else {
        setNotedButtonDisabled(false);
      }
      setSpecialRequestDetails(specialRequestData[0]);

      const MyTeam = await UserService.findTeam(specialRequestData[0].usersId);
      const usersArray = MyTeam.user;

      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);

      const loggedInUserTeam = await UserService.findTeam(parsedUser.id);
      const loggedInUsersArray = loggedInUserTeam.user;

      const isPermitted = usersArray.filter((users) => {
        return users['id'] === specialRequestData[0].usersId;
      });

      const isLoggedInUserPermitted = loggedInUsersArray.filter((users) => {
        return users['id'] === specialRequestData[0].usersId;
      });

      if (
        parsedUser.roleId === 1 ||
        parsedUser.roleId === 4 ||
        parsedUser.roleId === 8 ||
        parsedUser.id === specialRequestData[0].usersId ||
        (specialRequestData[0].subject === 'missed_attendance' &&
          isLoggedInUserPermitted.length > 0)
      ) {
      } else {
        history.push(`/special`);
      }
      if (parsedUser.id === specialRequestData[0].usersId) {
        setIsTeamLead(false);
      }

      if (isPermitted.length !== 0) {
        if (parsedUser.id !== specialRequestData[0].usersId) {
          setIsTeamLead(true);
        } else {
          setIsTeamLead(false);
        }
      } else {
        history.push(`/special`);
      }
    };
    fetchUserData(id);
  }, [id]);

  const closeSubmittingModal = () => {
    setIsSubmittingModalOpen(false);
  };

  const respondToRequest = async () => {
    setIsApproveModalOpen(false);
    setIsModalOpen(false);

    if (specialRequestDetails['status'] !== currentStatus) {
      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);

      setIsSubmitting(true);

      const allFilters = {
        dateFilter: 'date_range',
        attendanceTime: '',
        loggedInUserId: specialRequestDetails['usersId'],
        startDate: moment(specialRequestDetails['date'], 'D MMMM, YYYY').format(
          'YYYY-MM-DD',
        ),
        endDate: moment(specialRequestDetails['date'], 'D MMMM, YYYY').format(
          'YYYY-MM-DD',
        ),
        dateRange: null,
      };

      const resultData = await AttendanceService.filterFromView(allFilters);
      const data = {
        approveById: parsedUser.id,
        full_name: resultData[0].full_name,
        status: currentStatus,
        rejectReason: descriptionText,
        respondToRequest: 'respondToRequest',
        checkInId: resultData[0].recordTime.checkInId
          ? resultData[0].recordTime.checkInId
          : null,
        checkOutId: resultData[0].recordTime.checkOutId
          ? resultData[0].recordTime.checkOutId
          : null,
      };

      setCurrentStatus('');
      setIsSubmittingModalOpen(true);

      await SpecialRequestService.update(+id, data);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentStatus('');
  };

  const openModal = async (status) => {
    setIsModalOpen(true);
    setCurrentStatus(status);

    const sidebar = document.querySelector('.sidebarParent');
    const navbar = document.querySelector('.main__navbar__right');

    if (sidebar) {
      sidebar.classList.add('blurred__sidebar');
    }
    if (navbar) {
      navbar.classList.add('blurred__sidebar');
    }
  };

  const closeApproveModal = () => {
    setIsApproveModalOpen(false);
    setIsModalOpen(false);
    setCurrentStatus('');
  };

  const openApproveModal = async (status) => {
    setIsApproveModalOpen(true);
    setCurrentStatus(status);

    const sidebar = document.querySelector('.sidebarParent');
    const navbar = document.querySelector('.main__navbar__right');

    if (sidebar) {
      sidebar.classList.add('blurred__sidebar');
    }
    if (navbar) {
      navbar.classList.add('blurred__sidebar');
    }
  };

  const handleChangeDescription = (e) => {
    const { value } = e.target;
    setDescriptionText(value);
  };

  return (
    <Layout>
      <div className="leavesIndex">
        <div
          className="leavesIndex__header leavesIndex__body_view_screen"
          style={{ paddingBottom: 0 }}
        >
          <div>
            <h4 className="attendance_overview_heading">Special Request</h4>
          </div>

          <div style={{ display: 'flex' }}>
            {isTeamLead && specialRequestDetails['subject'] === 'other' && (
              <div
                className="leavesIndex__header_right"
                style={{ paddingRight: '1vw' }}
              >
                <button
                  onClick={() => openModal('noted')}
                  className={`approveButton ${
                    notedButtonDisabled ? 'disabled' : ''
                  }`}
                  disabled={notedButtonDisabled}
                >
                  Noted
                </button>
              </div>
            )}
            {isTeamLead && specialRequestDetails['subject'] !== 'other' && (
              <div
                className="leavesIndex__header_right"
                style={{ paddingRight: '1vw' }}
              >
                <button
                  onClick={() => openApproveModal('approved')}
                  className={`approveButton ${
                    approveButtonDisabled ? 'disabled' : ''
                  }`}
                  disabled={approveButtonDisabled}
                >
                  Approve
                </button>
                <button
                  onClick={() => openModal('rejected')}
                  className={`rejectButton ${
                    rejectButtonDisabled ? 'disabled' : ''
                  }`}
                  disabled={rejectButtonDisabled}
                >
                  Decline
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="leavesIndex__body leavesIndex__body_view_screen">
          <div className="leavesIndex__body_padding leavesIndex__body_padding_view_screen">
            <Box
              component="form"
              noValidate
              sx={{
                display: 'grid',
                gridTemplateColumns: { sm: '1fr 1fr' },
                gap: 2,
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="singleInputField">
                  <div className="mt-2 flex items-center">
                    <InputLabel
                      className="formInputLabels"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Name
                    </InputLabel>
                    <InputLabel
                      className="formInputLabels formInputLabels2"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      {specialRequestDetails['full_name']
                        ? specialRequestDetails['full_name'].length > 20
                          ? `${specialRequestDetails['full_name'].slice(
                              0,
                              20,
                            )}...`
                          : specialRequestDetails['full_name']
                        : ''}
                    </InputLabel>
                  </div>
                </div>
                <div className="singleInputField">
                  <div className="mt-2 flex items-center">
                    <InputLabel
                      className="formInputLabels"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Status
                    </InputLabel>
                    <InputLabel
                      className="formInputLabels formInputLabels2"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      {specialRequestDetails['status']}
                    </InputLabel>
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="singleInputField">
                  <div className="mt-2 flex items-center">
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Subject
                      </InputLabel>
                      <InputLabel
                        className="formInputLabels formInputLabels2"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        {specialRequestDetails['subject'] === 'multiple_leave'
                          ? 'Special Leave'
                          : specialRequestDetails['subject']}
                      </InputLabel>
                    </div>
                  </div>
                </div>
                {specialRequestDetails['subject'] === 'missed_attendance' ? (
                  <div className="singleInputField">
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Date
                      </InputLabel>
                      <InputLabel
                        className="formInputLabels formInputLabels2"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        {specialRequestDetails['date']}
                        {'  '}({specialRequestDetails['time']})
                      </InputLabel>
                    </div>
                  </div>
                ) : (
                  <div className="singleInputField">
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Date
                      </InputLabel>
                      <InputLabel
                        className="formInputLabels formInputLabels2"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        {specialRequestDetails['date']}
                        {specialRequestDetails['fromTime'] &&
                          ` (${specialRequestDetails['fromTime']})`}
                      </InputLabel>
                    </div>
                  </div>
                )}
              </div>
              {specialRequestDetails['subject'] === 'missed_attendance' &&
                specialRequestDetails['inputType'] && (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="singleInputField">
                      <div className="mt-2 flex items-center">
                        <div className="mt-2 flex items-center">
                          <InputLabel
                            className="formInputLabels"
                            shrink
                            htmlFor="bootstrap-input"
                          >
                            Input Type
                          </InputLabel>
                          <InputLabel
                            className="formInputLabels formInputLabels2"
                            shrink
                            htmlFor="bootstrap-input"
                          >
                            {specialRequestDetails['inputType'] === 'check_in'
                              ? 'Check In'
                              : 'Check Out'}
                          </InputLabel>
                        </div>
                      </div>
                    </div>
                    <div className="singleInputField"></div>
                  </div>
                )}
              {specialRequestDetails['subject'] === 'multiple_leave' && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {specialRequestDetails['subject'] === 'multiple_leave' &&
                    specialRequestDetails['reason'] && (
                      <div className="singleInputField">
                        <div className="mt-2 flex items-center">
                          <div className="mt-2 flex items-center">
                            <InputLabel
                              className="formInputLabels"
                              shrink
                              htmlFor="bootstrap-input"
                            >
                              Reason
                            </InputLabel>
                            <InputLabel
                              className="formInputLabels formInputLabels2"
                              shrink
                              htmlFor="bootstrap-input"
                            >
                              {specialRequestDetails['reason']}
                            </InputLabel>
                          </div>
                        </div>
                      </div>
                    )}

                  <div className="singleInputField">
                    <div className="mt-2 flex items-center">
                      <div className="mt-2 flex items-center">
                        <InputLabel
                          className="formInputLabels"
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          Duration
                        </InputLabel>
                        <InputLabel
                          className="formInputLabels formInputLabels2"
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          {specialRequestDetails['duration']}
                        </InputLabel>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div className="singleInputField">
                  {specialRequestDetails['approveById'] && (
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Approved By
                      </InputLabel>
                      <InputLabel
                        className="formInputLabels formInputLabels2"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        {specialRequestDetails['approve_by_full_name']}
                      </InputLabel>
                    </div>
                  )}
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="singleInputFieldTextArea singleInputFieldTextAreaView">
                  <InputLabel
                    className="formInputLabels viewScreenHeading"
                    shrink
                    htmlFor="bootstrap-input"
                  >
                    Description
                  </InputLabel>
                  <FormControl variant="standard">
                    <textarea
                      className={`leaveDurationTextArea leaveDurationInput leaveDurationTextAreaViewScreen`}
                      value={specialRequestDetails['description']}
                      placeholder="Enter text here..."
                      rows={12}
                      cols={50}
                      readOnly
                    />
                  </FormControl>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  margin: '1vw 0',
                }}
              >
                {(specialRequestDetails['status'] === 'rejected' ||
                  specialRequestDetails['status'] === 'noted') &&
                  specialRequestDetails['rejectReason'] !== null && (
                    <div className="singleInputField singleInputFieldTextArea ">
                      <InputLabel
                        className="formInputLabels viewScreenHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Reason For Rejection
                      </InputLabel>
                      <FormControl variant="standard">
                        <textarea
                          className={`leaveDurationTextArea leaveDurationInput leaveDurationTextAreaViewScreen`}
                          value={specialRequestDetails['rejectReason'] || ''}
                          placeholder="Enter text here..."
                          rows={6}
                          cols={50}
                          readOnly
                        />
                      </FormControl>
                    </div>
                  )}
              </div>
            </Box>
          </div>
        </div>
        <RejectionModal isOpen={isModalOpen} onClose={closeModal}>
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div
                className="ValidationModal"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                {specialRequestDetails['subject'] === 'other' ? (
                  <h3 className="rejectionHeading">Noted!</h3>
                ) : (
                  <h3 className="rejectionHeading">Decline</h3>
                )}
                <span className="rejectionText">
                  Would you like to provide any further information?
                </span>
                <FormControl variant="standard">
                  <textarea
                    className={`leaveDurationTextArea leaveDurationInput`}
                    placeholder="Mention Reason here (optional)"
                    rows={6}
                    cols={50}
                    onChange={handleChangeDescription}
                    value={descriptionText}
                  />
                </FormControl>
                <div className="CloseRejectionModalButton">
                  <button
                    type="button"
                    className="CloseRejectionModal"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={respondToRequest}
                    className={`submitValidationModal ${
                      isSubmitting ? 'disabled' : ''
                    }`}
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </RejectionModal>

        <RejectionModal isOpen={isApproveModalOpen} onClose={closeApproveModal}>
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div
                className="ValidationModal"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span className="approvalText">
                  Are You sure you want to approve this request !
                </span>

                <div className="CloseRejectionModalButton">
                  <button
                    type="button"
                    className="CloseRejectionModal"
                    onClick={closeApproveModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={`submitValidationModal ${
                      isSubmitting ? 'disabled' : ''
                    }`}
                    disabled={isSubmitting}
                    onClick={respondToRequest}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </RejectionModal>

        <ValidationModal
          isOpen={isSubmittingModalOpen}
          onClose={closeSubmittingModal}
        >
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div className="ProgressModal">
                <Box className="progressOfSubmitting" sx={{ width: '100%' }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                      height: 15,
                      borderRadius: 10,
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: '#1fc214',
                      },
                    }}
                  />
                </Box>
                <span>Submitting Request</span>
              </div>
            </div>
          </div>
        </ValidationModal>
      </div>
    </Layout>
  );
}
